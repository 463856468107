import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout/layout"
import * as Layout2Col from "../components/layout/layout2col/layout2col"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import HeroSimple from "../components/hero/heroSimple/heroSimple"
import BlockContent from "../components/blockContent/blockContent"
import Seo from "../components/seo/seo"

import EventCategories from "../components/event/eventCategories/eventCategories"
import ListItem from "../components/shared/listItem/listItem"
import Pagination from "../components/shared/pagination/pagination"

import styles from "./shared.module.scss"

const EventCategory = ({ data, pageContext }) => {
  const page = data.sanityEventCategory
  const seo = data.sanityEventCategory.seo
  const events = data.allSanityEvent
  const settings = data.sanityEventListing.tabs.content
  const { currentPage, totalPages, slug, pagePath } = pageContext

  const steps = [
    {
      link: `events`,
      title: "Events",
    },
    {
      link: `events/${slug}`,
      title: page.title,
    },
  ]

  return (
    <Layout>
      <Seo isBlogPost={false} title={page.title} path={pagePath} breadcrumbs={steps} {...seo} />
      <Breadcrumbs steps={steps} />
      <HeroSimple blocks={page._rawHero[0].children} subtitle={page.subtitle} />
      <Layout2Col.Wrapper>
        <Layout2Col.Main>
          {events.nodes.length ? (
            <>
              <div className="grid">
                {events.nodes.map(event => (
                  <ListItem {...event.tabs.content} base="events" />
                ))}
              </div>
              {totalPages > 1 && (
                <Pagination base={`events/${slug}`} currentPage={currentPage} totalPages={totalPages} />
              )}
            </>
          ) : (
            <div className={styles.noContent}>
              <BlockContent blocks={settings.text} />
            </div>
          )}
        </Layout2Col.Main>
        <Layout2Col.Sidebar>
          <EventCategories />
          <Link to="/events/past" className="link-pink">
            View past events
          </Link>
        </Layout2Col.Sidebar>
      </Layout2Col.Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $skip: Int!, $limit: Int!) {
    sanityEventCategory(slug: { current: { eq: $slug } }) {
      title
      _rawHero
      subtitle
      seo {
        canonical
        description
        metaTitle
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
        removeSitemap
      }
    }
    sanityEventListing {
      tabs {
        content {
          text: _rawText
        }
      }
    }
    allSanityEvent(
      filter: { tabs: { content: { type: { eq: "upcoming" }, category: { slug: { current: { eq: $slug } } } } } }
      sort: { fields: tabs___content___eventDate, order: ASC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        tabs {
          content {
            title
            subtitle
            dateDescription
            category {
              slug {
                current
              }
            }
            slug {
              current
            }
            excerpt
            featuredImage {
              asset {
                fluid(maxWidth: 250) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default EventCategory
